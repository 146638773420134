.bgWhite{
   background-color: white;
   height: calc(100vh - 96px);
}
.height{
   height: calc(100vh - 274px)
}
.sucesss{
   background-color: #00ad4d;
   color :white
  }
  .sucessFailed{
     background-color:#e6707b ;
     color: white;
  }