
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:wght@600;700&display=swap');

/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap'); */
@font-face {
  font-family: 'Montserrat', sans-serif;;
  src: url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:wght@600;700&display=swap');
}
body{
  font-family: 'Montserrat', sans-serif!important;
}

.main{
  position: relative;
  top: 0px;
  padding:  20px;
  /* width: calc(100% - 274px); */
  background-color: #F8FCFA;
  opacity: 1;
  /* left: 274px; */
  width: 100%;
  height: calc(100vh - 56px);
}
.Privacymain{
  position: relative;
  top: 0px;
  padding:  20px;
  height: calc(100vh - 56px);
  background-color: #F8FCFA;
  opacity: 1;
  width: 100%;
}
.colsLabel{
  text-align: right;
  margin: 10px;
  font-weight: bold;
}

.colsLabels{
  /* text-align: right; */
  /* padding: 18px 0px !important; */
  font-weight: bold;
}
.textboxmargin{
  margin: 10px;
  /* padding: 22px; */
  /* margin: 8px; */
}
input[type="file"] {
  position: absolute!important;
  height: 100%!important;
  width: 100%!important;
  opacity: 0!important;
  top: 0!important;
  left: 0!important;
}
a{
  color: initial!important;
  text-decoration: none!important;
}
tbody, td, tfoot, th, thead, tr{
  margin: 12px!important;
  padding: 0.5rem 0.5rem!important;
}
tr{
  width: 100%!important;
}
.btnColorSuccess{
 color: #fff!important;
 background: #222222 0% 0% no-repeat padding-box;
 border-radius: 6px;
 opacity: 1;
border: none;
color: white;
padding: 10px 14px 10px 34px;
text-align: center;
text-decoration: none;
display: inline-block;
font-size: 16px;
border-radius: 10px;    
}
.plus-icon{
  width: 12px;
  height: 12px;
  font-size: 27px;
  margin-left: 14px;
  position: absolute;
  left: 0;
  top: calc(50% - 6px);
}
.dataHeading{
  /* text-align: center; */
  font-size: 25px;
  margin: 0;
  padding: 0;
  font-weight:600;
}
  .headingPadding{
    padding: 27px 0px 17px 0px;
 }
 .customer-border{
  border: 1px solid #E5E6E6;
border-radius: 6px;
opacity: 1;
 }
 .tableWidth{
  width: 1000px;
 }
 .imgDetail{
  width: 18px;
  height: 4px;
  cursor: pointer;
}
.customtable{
  padding: 1.5rem;
}
.imagepadding{
  padding: 30px;
}
.isSold{
  padding: 0;
  font-weight: bold;
}
.imgarrow{
  padding: 0 15px 0 0;
  width: 50px;
  height: 25px;

}
.soldStatusDecline{
  width: 73px;
height: 28px;
/* UI Properties */
background: #FFDBDB 0% 0% no-repeat padding-box;
border-radius: 50px;
opacity: 1;
text-align: center;
}
.soldSatusFailed{
  width: 26px;
height: 15px;
/* UI Properties */
text-align: center;
font-size: 12px;
font-weight: bold;
/* font: normal normal medium 12px/30px Montserrat; */
letter-spacing: 0px;
color: #FC2A2A;
opacity: 1;
}
.soldStatusaccept{
  width: 73px;
height: 28px;
/* UI Properties */
background: #DBFFE0 0% 0% no-repeat padding-box;
border-radius: 50px;
opacity: 1;
text-align: center;
}
.soldSatussucess{
  width: 26px;
height: 15px;
/* UI Properties */
text-align: center;
font-size: 12px;
font-weight: bold;
/* font: normal normal medium 12px/30px Montserrat; */
letter-spacing: 0px;
color: #19AB4F;
opacity: 1;
}
.btncolor{
  background-color: #19AB4F!important;
  border: none!important;
}
.privacyBtn{
  
    margin-top: 65px;
    /* margin-bottom: 84px; */
    display: block!important;
    text-align: center;
    padding-bottom: 30px;

}
.navlistIcon{
 
  margin: 0;
  padding: 0;

}
.navlistIcon li{
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0 10px;
}
.edit{
    width: 20px;
    cursor: pointer;
}
.delete{
  width: 15px;
  cursor: pointer;
  height: 20px;
}
.disableActive{
  width: 20px;
  height: 20px;
  cursor: pointer;
  

}
.disable {
  cursor: pointer;
  width: 20px;
  opacity: 0.5;
  
}
.asterisk{
  color: red;
}
.form-group{
  padding: 10px 0px;
}
.err{
  color: red;
}
table{
  width: 100%!important;
  
  
}
.viewDetail{
  font-size: 20px;
  color: #00ad4d;
}
@media only screen and (min-width: 320px) and (max-width : 1024px)  {
  .main{
     padding: 0px;
     background-color: #F8FCFA;
  }
  .dataHeading{
    text-align: center;
    font-size: 14px;
  }
  
   .tableWidth{
    width: 1000px!important;
   }
   .customtable{
     padding: 10px;
     overflow-x: scroll;
  }
  .colsLabel{
    text-align: left;
    padding: 0px!important;
 }
 .textboxmargin{
  padding: 0px;
 }
 .imagepadding{
  padding: 10px;
}
.imgDeleteIcon{
 
  position: absolute;
  background: #00ad4d;
  width: 50px!important;
  line-height: 45px;
  height: 50px;
  right: 26px;
  text-align: center;
  border-radius: 50%;

}
.textboxmargin{
  padding: 0px;
  margin: 0px;
}
}