.main{
   position: absolute;
   top: 60px;
   padding:  20px;
   width: 100%;
 }
.modalform{
   padding: 5px 10px;
}

.heading{
   padding: 0px;
   // border: 1px solid;
}

.disabled{
   // pointer-events: none;
    cursor: default;
    text-decoration: none;
   //  color: red!important;
   
  }   
  .pageColor{
   color: white!important;
   background-color: #00AD4D;
   font-weight: 600;
  }
  .itemClass{
   color: white;
  }
@media only screen and (min-width: 320px) and (max-width : 768px)  {
   .main{
      padding: 0px;
   }
   .heading{
      padding: 0px;
      width: 100%;
      text-align: center;
   }
  
}