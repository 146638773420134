.firstTextbox{
   width: 100%!important;
   // height: 56px!important;
   border-radius: 10px!important;
   background-color: #ffffff10!important;
   margin: 0px!important;
   font-size: 14px;
   font-family: 'Montserrat'!important;
   padding: 0!important;
   border-radius: 10px!important;
}

.firstTextboxerror{
   width: 300px!important;
   background-color: #ffffff10!important;
   margin: 0px 10px!important;
   font-size: 14px;
   font-weight: bold;
   font-family: 'Montserrat'!important;
   padding: 0!important;
   border-radius: 10px!important;
}
.labelCatagories{
   margin: 10px;
   font-size: 18px;
}
.colsLabel{
   text-align: right;
   margin: 10px;
 }
 .descriptionfeatures{
   padding: 8px;
 }
 .descriptionfeaturesheading{
   padding: 15px;
 }
 .descriptionHeading{
   color: #222222;
   font-size: 20px;
   font-weight: bold;
   padding-bottom: 20px;
}
.mainheading{
   color: #222222;
   font-size: 25px;
   font-weight: bold;
   padding-bottom: 20px;
   text-align: left;
   margin: 0px 20px;
}
.fileUpload{
   color: #222222;
   font-size: 25px;
   font-weight: bold;
   // padding-bottom: 20px;
   text-align: left;
   margin: 0px 30px;
}
.uploadOuter {
   position: relative;
   width: 100%;
   height: 400px;
   /* UI Properties */
   border: 1px dashed var(--unnamed-color-1fb9fc);
   background: #F1F7FF 0% 0% no-repeat padding-box;
   border: 1px dashed #1FB9FC;
   border-radius: 3px;
   // opacity: 1;
   text-align: center;
}
.uploadOuterV1 {
   position: relative;
   width: 100%;
   height: 400px;
   /* UI Properties */
   border: 1px dashed var(--unnamed-color-1fb9fc);
   background: #F1F7FF 0% 0% no-repeat padding-box;
   border: 1px dashed #1FB9FC;
   border-radius: 3px;
   opacity: 0.6;
   text-align: center;
}
.dragBox {
   line-height: 300px;
   font-weight: bold;
   font-size: 24px;
   width: 100%;
}
.uploadIcon{
   position: absolute;
   font-size: 36px;
   left: 45%;
   top: 50%;
   width: 70px;
}
.imgthumbail{
   width: 50px;
   height: 50px;
}
.imgWidth{
   width: 100%;
   height: 100%;
   cursor: pointer;
}
.popupMessage{
   text-align: center;
   width: 100%;
}
.popuptitle{
   text-align: center;
}
.imageProgress{
   height: 5px;
}
.colorWhite{
   padding: 0px!important;
   margin: 0px!important;
   height: 2.35rem;
   line-height: 0;
}
.sucesss{
   background-color: #00ad4d;
   color :white
  }
  .sucessFailed{
     background-color:#e6707b ;
     color: white;
  }
@media only screen and (min-width: 320px) and (max-width : 992px)  {
   .firstTextbox{
      width: 100%!important;
      margin: 0px!important;
   }
   .descriptionfeatures{
      padding: 0px 0px 15px 0 ;
    }
    .descriptionfeaturesheading{
      padding: 0px;
    }
    .Equipment_uploadOuterV1__3P7Oa {
      position: relative;
      width: 100%;
      height: 400px;
      border: 1px dashed var(--unnamed-color-1fb9fc);
      background: #F1F7FF 0% 0% no-repeat padding-box;
      border: 1px dashed #1FB9FC;
      border-radius: 3px;
      opacity: 0.8;
      text-align: center;
  }
  .uploadOuterV1 {
   position: relative;
   width: 100%;
   height: 200px;
   /* UI Properties */
   border: 1px dashed var(--unnamed-color-1fb9fc);
   background: #F1F7FF 0% 0% no-repeat padding-box;
   border: 1px dashed #1FB9FC;
   border-radius: 3px;
   opacity: 0.8;
   text-align: center;
}
.uploadIcon{
   position: absolute;
   font-size: 36px;
   left: 100px;
   top: calc(100px - 50px);
   width: 70px;
}
.imgWidth{
   width: 100%;
   height: 100%;
   cursor: pointer;
   position: relative;
}
.closeIcon{
   text-align: center;
    line-height: 50px;
    color: white;
}
}