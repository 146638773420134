.column{
   background-color: white;
   height: 100%;
   box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
   padding: 20px;
}
.heading{
   font-size: 18px;
   font-weight: bolder;
}
.message{
   color: red;
   // font-weight: bold;
   text-align: center;
   padding: 14px 0 0 0 ;
}
.sucesss{
   background-color: #00ad4d;
   color :white
  }
  .sucessFailed{
     background-color:#e6707b ;
     color: white;
  }
  .toastMessage{
   position: absolute;
   bottom: 0;
   width: 100%;
   right: 0;
  }
  .imgIcon{
   position: absolute;
   right: 25px;
   top:10px
}