.body {
   background-color: #19AB4F;
   background-repeat: no-repeat;
   background-position: center;
   background-size: cover;
   width: 100%;
   height: 100vh;
   font-family: poppins;
   min-height: 600px;
   position: relative;

}

.backgroundImage {
   top: 0px;
   left: 0px;
   width: 100%;
   height: 100vh;

   opacity: 1;
}

.login {

   border-radius: 5px;
   width: 420px;
   background: #fff;
   max-width: 420px;
   padding: 16px 32px;
   text-align: center;
   box-shadow: 0 8px 10px -5px rgb(0 0 0 / 20%), 0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%);
   margin: auto;
   height: 500px;
}

.logo {
   width: 101px;
   height: 101px;
}

.name {
   font-size: 26px;
   color: #2B3D5F;
   font-weight: bold;
   font-family: poppins;
}

.description {
   font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-12)/18px var(--unnamed-font-family-poppins);
   letter-spacing: var(--unnamed-character-spacing-0);
   color: #2b3d5f;
   text-align: center;
   font: normal normal normal 12px/18px Poppins;
   letter-spacing: 0px;
   color: #2B3D5F;
   opacity: 1;
}

.email {
   position: relative;
}
.imgIcon{
   position: absolute;
   right: 25px;
   top:10px
}
.fontIcon {
   position: absolute;
   left: 0;
   left: 21px;
   top: 11px;
}

.emailTextBox {
   display: block;
   width: 100%;
   padding: 0.375rem 2.75rem;
   font-size: 1rem;
   line-height: 1.5;
   color: #495057;
   background-color: #fff;
   background-clip: padding-box;
   border: 1px solid #ced4da;
   border-radius: 0.25rem;
   transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

}

.fontIconPassword {
   position: absolute;
   left: 15px;
   top: 7px
}

.btnLogin {
   width: 112px;
   border-radius: 6px;
   border-color: #19AB4F;
   height: 54px;
   background-color: #19AB4F;
   color: #fff;
}

.forgotPassword {
   text-align: center;
   color: #2B3D5F;
   font-size: 12px;
   font-family: poppins;
   font-weight: 600;
   cursor: pointer;
}

.forgotPassword {
   text-decoration: none;
}

.forgotPassword:hover {
   color: #2B3D5F;
}


// media query
@media only screen and (min-width: 320px) {
   .login {

      border-radius: 5px;
      width: 100%;
      background: #fff;
      // max-width: 1005;
      padding: 16px 32px;
      text-align: center;
      box-shadow: 0 8px 10px -5px rgb(0 0 0 / 20%), 0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%);
      margin: auto;
      height: 100%;
   }

   .backgroundImage {
      height: 100%;
   }
}