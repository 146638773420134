.firstTextbox{
   width: 100%!important;
   // height: 56px!important;
   border-radius: 10px!important;
   background-color: #ffffff10!important;
   margin: 0px!important;
   font-size: 14px;
   font-family: 'Montserrat'!important;
   padding: 0px!important;
   border-radius: 10px!important;
}
.main{
   background-color: white;
  margin: 20px 0px;
  padding: 20px;
  
}
.uploadOuterV1 {
   position: relative;
   width: 100%;
   height: 400px;
   /* UI Properties */
   border: 1px dashed var(--unnamed-color-1fb9fc);
   background: #F1F7FF 0% 0% no-repeat padding-box;
   border: 1px dashed #1FB9FC;
   border-radius: 3px;
   opacity: 0.8;
   text-align: center;
}

.imagepadding{
   color: red;
   padding: 10px 0px;
   font-weight: 600;
   font-size: 16px;
}
.uploadIcon{
   width: 70px;
   height: 50px;
   position: absolute;
   top: calc(50% - 70px);
   left: calc(50% - 50px);
}
.dragBox{
   align-items: center;
}
.imgtextCenter{
   position: relative;
   line-height: 400px;
 
   font-size: 25px;
}
.fileUpload{
   // text-align: center;
   text-align: left;
   font-weight: bold;
   padding: 0px 10px;
}
.imageBorder{
   border: 1px solid;
   width: 70px;
   height: 70px;
}
.imgeUploadPaadding{
   padding: 10px 0px;
}
  .imgWidth{
   width: 100%;
   height: 60px;
  } 
  .colorWhite{
   padding: 0px!important;
   margin: 0px!important;
   height: 2.35rem;
   line-height: 0;
}
.closeIcon{
   font-size: 24px;
}
@media only screen and (min-width: 320px) and (max-width : 992px)  {
   .firstTextbox{
      width: 100%!important;
      margin: 0px!important;
   }
   .fileUpload{
     font-size: 18px;
   }
   .uploadOuterV1{
      height: 200px;
   }
   .imgtextCenter{
      position: absolute;
      line-height: 0;
      font-size: 21px;
      top: 50%;
      left: 0;
      text-align: center;
      width: 100%;
  }
   
}