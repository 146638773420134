.navColumn{
   width: 314px;
   height: 100vh;
   box-shadow: 3px 3px 15px #0000000A;
   opacity: 1; 
   background: #fff;
   overflow: hidden;
   margin: 0;
   padding: 0;
   //  transition: all 0.5ms;
   transition: all 0.3s!important;
   position: fixed;
   z-index: 999;
 }
 .topBar{
   position: relative;
    height: 56px;
   //  position: fixed;
    left: 314px;
    background-color: white;
    box-shadow: 3px 3px 15px #0000000A;
 }
 
 .main{

   width: 100%;
   transition: all 0.3s!important;
   padding: 0;
 }
 .logo{
    width: 90px;
    height: 90px;
 }
 .navbar{
    margin: 0;
    padding: 0px 20px;
    list-style-type: none;
 }
 .navbar li{
width: 100%;
 font-size: 16px;
 color: #2B3D5F;
 opacity: 1;
 padding:  14px 0 14px 20px;
 border-radius: 4px;
opacity: 1;
 }

 .active {
  background-color: #00AD4D;

 }
 .activeColor{
  color: white;
 }
 .navLink{
    text-decoration: none;
    color: #2B3D5F;
 }
 .icons{
    color: #2B3D5F;
    font-weight: 800;
    padding: 7px;
    font-size: 24px;
 }
 .imgSize{
    width: 20px;
    height: 20px;
 }
 .navbarFixed{
  height: 50vh;
  width: 100%;
  min-height: 455px;
  position: static;
  overflow-x: hidden;
 }
 /* width */
 ::-webkit-scrollbar {
    width: 5px;
    height: 2px;
    
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #00ad4d; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #00ad4d;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background:  #00ad4d;
  }
  .LogoutBtn{
    text-align: center;
    width: 314px;
    position: absolute;
    bottom: 5px;
    justify-content: center;
    align-items: center;
 
  }
  .btnLogout{
 
    color: #00AD4D;
 
    font-weight: 800;
    font-size: 16px;
    text-align: center;
    background-color:white; 
  border: none;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
    
  }
  .logoSize{
    height: 18vh;
  }
  .logoIcon{
     display: flex;
     justify-content: center;
     align-items: center;
     padding: 33px;
 
  }
  .toogle{
    width: 50px;
  }
  .toogle1{
    width: 50px;
  }
  .fontSize28{
    font-size: 28px;
   //  display: none;
  }
  .imgAdmin{
    width: 40px;
    height: 40px;
  }
 .fontSize28{
   display: none;
   margin: 6px;
 }
 .navColumn1{
 // display: none;
 width: 0;
 margin: 0;
 transition: all 0.3s!important;
 overflow: hidden;
 padding: 0;
 height: 100vh;
 position: relative;
 z-index: 99;
 background-color: #fff;
 }
 .navColumn2{
 width: calc(100% - 314px);
 margin: 0;
 padding: 0;
 }
 .navColumn3{
 width: 100%;
 margin: 0;
 padding: 0;
 }
 
 .nav_overlay{
   position: fixed;
   top: 0px;
   left: 0px;
   right: 0;
   bottom: 0;
   background-color: #000;
   opacity: .6;
   z-index: 9;
   display: none;
 }
 .nav_overlayOff{
   position: fixed;
   top: 0px;
   left: 0px;
   right: 0;
   bottom: 0;
   background-color: #000;
   opacity: .6;
   z-index: 9;
   display: block;
 }


  @media only screen and (min-width: 320px) and (max-width : 1024px)  {
    
    .navColumn1{
     width: 250px;
     overflow: hidden;
     // width: 0;
    }
    .navColumn{

     width: 0;
     
    }
   .navColumn2{
     width: 100%;
   }
   .navColumn3{

    width: 100%;
    position: fixed;
    z-index: -20;
   }
   .topBar{
    left: 0;
   }
   .fontSize28{
    display: block;
   } 
  }
  .active {
    color: red;
  }
