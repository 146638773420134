.paddingBox{
   padding: 14px;
}
.fontData{
   font-weight: 800;
   padding: 10px 0 ;
}
.dashboardCardAmount{
   color: #222222;
opacity: 1;
font-size: 30px;
font-weight: bold;
}
.dashboardCard{
   font-size: 12px;
   opacity: 0.5;
   color: #222222;
}
.dashboardIcon{
   padding: 1rem;
}
.dashboardEquipment{
   background-color: #FFCFCF;
   padding: 7px;
   width: 50px;
   height: 50px;
   border-radius: 7px;
}
.dashboardSold{
   background-color: #CFFFF2;
   padding: 7px;
   width: 50px;
   height: 50px;
   border-radius: 7px; 
}
.brand{
   background-color: #CFDAFF;
   padding: 7px;
   width: 50px;
   height: 50px;
   border-radius: 7px; 
}
.imgEquipment{
   width: 100%;
   height: 100%;
   padding: 7px;
}
.equipmentData{
   padding-top: 20px;
}
.dataHeading{
   font-size: 16px;
   font-weight: bold;
   padding: 21px;
   margin: 0;
   line-height: 0;
}


.dashboardShadow{
   box-shadow: 0px 4px 10px #0000000D;
border-radius: 6px;
opacity: 1;
}

@media only screen and (min-width: 320px) and (max-width : 768px)  {
   .main{
      padding: 0px;
   }
   .dataHeading{
     text-align: center;
     font-size: 14px;
   }
  
 }
