.main{
   position: absolute;
   top: 60px;
   padding:  20px;
   width: 100%;
 }
.modalform{
   padding: 5px 10px;
}
.plusIcon
{
   font-size: 29px;
   margin-left: 14px;
   margin-top:10px ;
}
// .paddingChecked{
//    padding: 20px 40px;
// }
.heading{
   padding: 0px;
   // border: 1px solid;
}
.colorPrimary{
   color: red;
}
.paddingChecked{
   margin-right:5px;
}
@media only screen and (min-width: 320px) and (max-width : 768px)  {
   .main{
      padding: 0px;
   }
   .heading{
      padding: 0px;
      width: 100%;
      text-align: center;
   }
  
}